.hidden {
  visibility: hidden;
}

.navbar {
  padding: 5px 10%;
}

.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
}

.main {
  padding-top: 66px;
}

.navbar .logo {
  border: 1px solid #7a7a7a;
  width: 40px;
  height: 40px;
  padding-top: 2px;
  padding-left: 5px;
  border-radius: 4px;
}

.bottom-chevron {
  color: #4a4a4a;
  font-size: 30px;
}

.bottom-chevron:hover {
  -webkit-animation: shake 0.5s;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  50% {
    transform: translate(0px, 6px) rotate(-1deg);
  }

  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.section-header {
  position: relative;
  font-size: 30px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}
.section-header:before,
.section-header:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: "\a0";
  background-color: #a7a7a7;
}
.section-header:before {
  margin-left: -50%;
  text-align: right;
}

.about-wrapper,
.project-wrapper {
  background-color: #fafafa;
  width: 100%;
}

.about,
.experience,
.project,
.contact {
  width: 750px;
  margin: auto;
}

.about {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-header {
  margin-bottom: 50px;
}

.about div p {
  padding-bottom: 12px;
}

.experience {
  padding-top: 120px;
  padding-bottom: 120px;
}

.experience .columns:nth-child(2) .is-3 {
  border-right: 1px solid #a7a7a7;
}

.experience .columns:nth-child(2) {
  padding-bottom: 40px;
}

.experience .columns:nth-child(3) {
  flex-direction: row-reverse;
}

.experience .columns:nth-child(3) .is-3 {
  border-left: 1px solid #a7a7a7;
}

.experience .is-size-4 {
  line-height: normal;
  padding-bottom: 6px;
}

.experience .is-3:nth-child(2) {
  border-right: none;
  border-left: 1px solid #a7a7a7;
}

.experience .is-3 p:nth-child(2) {
  border-bottom: 1px solid #a7a7a7;
  padding-bottom: 5px;
}

.experience .is-3 p:nth-child(3) {
  color: hsl(0, 0%, 50%);
  padding-top: 5px;
}

.experience .is-9 li {
  margin-left: 20px;
  margin-bottom: 5px;
  list-style: disc;
}

.project {
  padding-top: 120px;
  padding-bottom: 120px;
}

.project li {
  color: #808080;
}

.project > div:first-child {
  margin-bottom: 100px;
}

.project .featured-content > div:first-child {
  margin-bottom: 50px;
}

.project .featured-details-left p {
  text-align: right;
}

.project .featured-details-left ul,
.project .featured-details-left .icon-container {
  text-align: right;
}

.github-icon {
  height: 17px;
}

.visit-icon {
  height: 21px;
  width: 18px;
  position: relative;
  top: 2px;
}

.project .featured-content img {
  border-radius: 5px;
}

.project .featured-details-left li {
  display: inline-block;
  margin-left: 5px;
}

.project .featured-details-left p:nth-child(2) {
  border-bottom: 1px solid #a7a7a7;
  padding-bottom: 0.5rem;
}

.project .featured-details-right li {
  display: inline-block;
  margin-right: 5px;
}

.project .featured-details-right p:nth-child(2) {
  border-bottom: 1px solid #a7a7a7;
  padding-bottom: 0.5rem;
}

.project .other-project .is-10 p:nth-child(2) {
  border-bottom: 1px solid #a7a7a7;
  padding-bottom: 0.5rem;
}

.project .is-size-7 {
  padding-top: 0.5rem;
}

.project .other-project .is-10 li {
  display: inline-block;
  padding-right: 5px;
}

.project .other-project .is-2 {
  padding-right: 0px;
}

.project .other-project .is-2 img {
  padding: 5px;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
}

.project .github-icon {
  margin-right: 6px;
}

.project a:first-child:hover path {
  stroke: #485fc7;
}

.project a:nth-child(2):hover path {
  fill: #485fc7;
}

.contact {
  padding-top: 120px;
  padding-bottom: 50px;
}

.contact .is-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact a {
  margin-top: 50px;
  margin-bottom: 50px;
}

.footer img {
  padding-left: 4px;
}

.footer .github-icon {
  height: 16px;
}

.footer a:hover path {
  stroke: #485fc7;
}

.footer .linkedIn-icon {
  height: 16px;
}

@media only screen and (max-width: 1023px) {
  .about,
  .experience,
  .project,
  .contact {
    width: 600px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar .logo {
    border: 1px solid #7a7a7a;
    width: 30px;
    height: 30px;
    padding-top: 0px;
    padding-left: 3px;
    border-radius: 4px;
    font-size: 0.85rem;
  }

  .hero,
  .about,
  .experience,
  .project,
  .contact {
    width: 100vw;
    max-width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .about {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section-header {
    margin-bottom: 30px;
    font-size: 24px;
  }

  .about .columns > div:first-child {
    font-size: 0.85rem;
    width: 100%;
  }

  .about .is-3 {
    margin: auto;
    width: 40%;
    min-width: 175px;
  }

  .experience {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .experience .columns:nth-child(2) {
    padding-bottom: 30px;
  }

  .experience .columns:nth-child(2) .is-3 {
    border: none;
  }

  .experience .columns:nth-child(3) .is-3 {
    border: none;
  }

  .experience .is-3 p:nth-child(2) {
    border: none;
    padding-bottom: 0px;
  }

  .experience .is-3 p:nth-child(3) {
    padding-top: 0px;
  }

  .experience .columns {
    font-size: 0.85rem;
  }

  .project {
    padding-top: 80px;
    padding-bottom: 80px;
    font-size: 0.85rem;
  }

  .project .featured-content > div:first-child {
    display: flex;
    flex-direction: column-reverse;
  }

  .project .featured-content .img-container {
    display: flex;
    justify-content: center;
  }

  .project .featured-details-left p:nth-child(2),
  .project .featured-details-right p:nth-child(2),
  .project .other-project .is-10 p:nth-child(2) {
    border-bottom: none;
    padding-bottom: 0px;
  }

  .project .featured-content img {
    max-width: 300px;
  }

  .project .is-size-7 {
    padding-top: 3px;
  }

  .project .featured-details-left p,
  .project .featured-details-left ul,
  .project .featured-details-left .icon-container {
    text-align: left;
  }

  .project .featured-details-left li {
    margin-left: 0px;
    padding-right: 5px;
  }

  .project .other-project .is-2 {
    width: 80px;
    margin: auto;
  }

  .contact {
    font-size: 0.85rem;
    padding-top: 80px;
  }

  .contact a {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .footer {
    font-size: 0.85rem;
  }

  .icon-container {
    height: 15px;
  }

  .github-icon {
    height: 14px;
  }

  .visit-icon {
    height: 15px;
  }

  .footer .linkedIn-icon {
    height: 14px;
  }

  .footer .github-icon {
    height: 14px;
  }
}
